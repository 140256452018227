import axios from "axios";

export const generateID = () => {
    const S4 = () => (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
    return (S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4())
}

export const fetchAPITodos = () => {
/*
    axios
        // .post('https://l2i6h1eyah.execute-api.us-west-2.amazonaws.com/mysqlExample02',
        // .post('https://vk9a1az35h.execute-api.us-west-2.amazonaws.com/default/mysqlExample02',
        .post('https://vk9a1az35h.execute-api.us-west-2.amazonaws.com/default/api-to-lambda-to-RDS-MySQL',
            {

                // "action": {
                //     "type": "SOME_ACTION_THAT_IS_NOT_AVAILABLE_FOR_EXAMPLE"
                // }

                "action": {
                    "type":"LIST_ALL"
                }

                // "action": {
                //     "type": "GET_ITEM",
                //     "id": 2
                // }

                // "action": {
                //     "type": "INSERT_ITEM",
                //     "display_name": "GORDON",
                //     "birth_year": 1974
                // }

                // "action": {
                //     "type": "UPDATE_ITEM",
                //     "id": 9,
                //     "display_name": "FRANK",
                //     "birth_year": 1974
                // }

                // "action": {
                //     "type": "DELETE_ITEM",
                //     "id": 10
                // }

            },
            {
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
            })
        .then((responseData) => {
                console.dir(responseData);
            }
        )
        .catch((err) => {
            console.dir(err);
        });
*/

    return new Promise((resolve) =>
        setTimeout(() => resolve([
            {id: generateID(), title: 'Write React Hooks book', completed: true},
            {id: generateID(), title: 'Promote book', completed: false}
        ]), 100)
    );
}


export const fetchAPIProfiles = () =>
    axios
        .get('https://vk9a1az35h.execute-api.us-west-2.amazonaws.com/default/api-to-lambda-to-RDS-MySQL',
            {

                // "action": {
                //     "type": "SOME_ACTION_THAT_IS_NOT_AVAILABLE_FOR_EXAMPLE"
                // }

                "action": {
                    "type":"LIST_ALL"
                }

                // "action": {
                //     "type": "GET_ITEM",
                //     "id": 2
                // }

                // "action": {
                //     "type": "INSERT_ITEM",
                //     "display_name": "GORDON",
                //     "birth_year": 1974
                // }

                // "action": {
                //     "type": "UPDATE_ITEM",
                //     "id": 9,
                //     "display_name": "FRANK",
                //     "birth_year": 1974
                // }

                // "action": {
                //     "type": "DELETE_ITEM",
                //     "id": 10
                // }

            },
            {
                headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
            })
        /*
        .then((responseData) => {
                console.dir(responseData);
            }
        )
        .catch((err) => {
            console.dir(err);
        });
        */

new Promise((resolve) =>
    setTimeout(() => resolve([
        {id: generateID(), title: 'Write React Hooks book', completed: true},
        {id: generateID(), title: 'Promote book', completed: false}
    ]), 100)
)