import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
// import axios from "axios";

import { fetchTodos, fetchProfiles } from '../actionsCreators'

import Header from './Header'
import AddTodo from './AddTodo'
import TodoList from './TodoList'
import TodoFilter from './TodoFilter'

export default function App () {

  const dispatch = useDispatch() //useDispatch hook from react-redux

  useEffect(() => {
    dispatch(fetchTodos());
    dispatch(fetchProfiles());
  }, [ dispatch ])

  return (
    <div style={{ width: 400 }}>
      <Header />
      <AddTodo />
      <hr />
      <TodoList />
      <hr />
      <TodoFilter />
    </div>
  )
}
