import { FETCH_TODOS, FETCH_PROFILES, ADD_TODO, TOGGLE_TODO, REMOVE_TODO, FILTER_TODOS } from './actionTypes'
import { fetchAPITodos, fetchAPIProfiles } from './api'

//todo:refactor all as createaActionZzzz

export function fetchTodos () { // async action creator
  return async (dispatch) => {
    const todos = await fetchAPITodos()
    dispatch({ type: FETCH_TODOS, todos })
  }
}

export function fetchProfiles () { // async action creator
  return async (dispatch) => {
    const profiles = await fetchAPIProfiles()
    dispatch({ type: FETCH_PROFILES, profiles })
  }
}




export function addTodo (title) {
  return { type: ADD_TODO, title }
}

export function toggleTodo (id) {
  return { type: TOGGLE_TODO, id }
}

export function removeTodo (id) {
  return { type: REMOVE_TODO, id }
}

export function filterTodos (filter) {
  return { type: FILTER_TODOS, filter }
}
