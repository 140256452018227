

export const FETCH_TODOS = 'FETCH_TODOS'
export const FETCH_PROFILES = 'FETCH_PROFILES' //todo
export const FETCH_CLI_COMMANDS = 'FETCH_CLI_COMMANDS' //todo

export const ADD_TODO = 'ADD_TODO'

export const TOGGLE_TODO = 'TOGGLE_TODO'

export const REMOVE_TODO = 'REMOVE_TODO'

export const FILTER_TODOS = 'FILTER_TODOS'
