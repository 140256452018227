import {combineReducers} from 'redux'

//todo: replace that with uuid module:
import {generateID} from './api'

// NOTE: each redux reducer handles a state that represent a part of the full react state tree, that part of the state is referred as sate in a redux reducer

function filter(state = 'all', action) {
    if (action.type === 'FILTER_TODOS') { return action.filter }
    else { return state } // IMPORTANT: redux reducers must return the current state for any unhandled action
}

function todos(state = [], action) { // default initial value for todos is set to []
    switch (action.type) {
        case 'FETCH_TODOS':
            return action.todos
        case 'ADD_TODO':
            const newTodo = { id: generateID(), title: action.title, completed: false }
            return [newTodo, ...state]; // never modify the current state always return newly created state object
        case 'TOGGLE_TODO':
            return state.map(t => {  // never modify the current state always return newly created state object
                if (t.id === action.id) return {...t, completed: !t.completed}  // modify matching item
                return t; // return non-matching items unchanged
            }, [])
        case 'REMOVE_TODO':
            return state.filter(t => {  // never modify the current state always return newly created state object
                if (t.id === action.id) return false;
                return true;
            })
        default:
            return state; // IMPORTANT: redux reducers must return the current state for any unhandled action
    }
}

function profiles(state = [], action) {  // default initial value for todos is set to []
    switch (action.type) {
      case 'FETCH_PROFILES': //TODO: enhance actionTypes to replace those with a structure like ReduxAction.FETCH_PROFILES etc...
            return action.profiles //it is ok to just return action.profiles here because profiles will have been fetched by the asynchronous action creator that will then dispatch the action after
        default:
            return state; // IMPORTANT: redux reducers must return the current state for any unhandled action
    }
}


const appReducer = combineReducers({todos, profiles, filter})
export default appReducer
